import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Heading,
  Text,
  Width,
  CreatePassword,
} from "@/components/index"

export default function Submitted() {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO title="Application Submitted" description="" noindex={true}/>
      <Box
        bg="agate"
        minheight="100%"
        height="100%"
        z
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["16.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Width
          display="flex"
          flexDirection={["column", null, null, "row"]}
          pt={["0", null, null, "6rem"]}
          pb={["0", null, null, "3.6rem"]}
        >
          <Box
            width={["100%", null, null, 6 / 12]}
            textAlign="center"
            mx="auto"
          >
            <Heading level="2" mb="0.6rem">
              Application Submitted!
            </Heading>
            <Text mb="3.2rem">
              Thank you for your interest in the Sonora Guitar Intensive. We
              have received your application. We will be sending you an email
              shortly with next steps.
            </Text>
          </Box>
        </Width>
      </Box>
    </Layout>
  )
}
